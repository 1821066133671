import "scss/app.scss"
import { useEffect } from "react"
import { Provider, ErrorBoundary } from "@rollbar/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { AppPropsType } from "next/dist/shared/lib/utils"
import { useRouter } from "next/router"
import TagManager from "react-gtm-module"
import queryClient from "../lib/query-client"
import * as tracking from "../lib/tracking"

function MyApp({ Component, pageProps }: AppPropsType) {
  // @ts-ignore
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  useEffect(() => {
    if (tracking.GTM_ID) {
      TagManager.initialize({ gtmId: tracking.GTM_ID })
    }
    tracking.pageView(router.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      tracking.pageView(url)
    }

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  return getLayout(
    <Provider
      config={{
        accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
        environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
      }}
    >
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>,
  )
}

export default MyApp
