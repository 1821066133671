export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

export const pageView = (url: string) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({ event: "pageview", page: { path: url } })
  }

  if (process.env.NODE_ENV !== "production") {
    console.log({ url })
  }
}

export const event = ({
  category,
  name,
  action,
  customFields,
}: {
  category: string
  name: string
  action: string
  customFields: Record<string, object>
}) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      category,
      name,
      action,
      details: customFields,
    })
  }

  if (process.env.NODE_ENV !== "production") {
    console.log({ category, name, action, details: customFields })
  }
}
